.App {
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  background-color: #ebebeb;
  overflow: hidden;
  overscroll-behavior: none;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  padding-left: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  margin: 10px 0;
  font-size: 5em;
  background: linear-gradient(0.25turn, #d962c4 0%, #7084ff 41%, #3afff3 100%);
  background-position: center;
  background-size: cover;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.App-link {
  color: white;
  padding: 10px;
  background: linear-gradient(0.1turn, #d962c4 0%, #7084ff 100%);
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  transition: 0.5s;
  background-size: 200% auto;
  margin: 5px;
}

.App-link:hover {
  background-position: right center;
}

.screenshot {
  height: 100vmin;
  resize: vertical;
  margin-right: -500px;
}
